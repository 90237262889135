<template>
	<div class="grid">
		<div class="col-12 md:col-6" v-if="selectedStore.id">
			<div class="card p-fluid">
				<h5>Custom Charge</h5>
				<p>
					<span class="customer-badge status-qualified" data-v-61bc1bb6="">Selected Store</span> : <span
						class="product-badge status-instock">{{ selectedStore.shop }}</span>
				</p>
				<div class="field">
					<label for="amount">Enter Amount</label>
					<InputText v-model="amount" id="amount" type="number" max="3000" min="1" @change="inputHandler"
						maxlength="3000" />
				</div>
				<div class="field">
					<label for="age1">Description</label>
					<Textarea id="description" rows="4" v-model="description" maxlength="200" />
				</div>

				<Button label="Add Charge" class=" p-3 text-xl" @click="addCustomCharge"
					v-bind:disabled="loading == true || amount <= 0"></button>
				<i class="pi pi-spin pi-spinner" style="font-size: 2rem" v-if="loading == 1"></i>
			</div>
		</div>

		<div class="col-12">
			<div class="card">

				<h5> Charge History </h5>
				<DataTable :value="customCharges" :rows="25" :paginator="true" responsiveLayout="scroll"
					:sortOrder="-1" sortField="id">
					<Column field="chargeId" header="TransactionId" :sortable="uniqueIden" style="width:10%"></Column>
					<Column field="dateUpdated" header="Last Updated" :sortable="true" style="width:20%"></Column>
					<Column field="description" header="Description" style="width:45%" class="wid1"></Column>
					<Column field="amount" header="Amount" :style="{ width: '10%' }">
						<template #body="{ data }">
							<span style="margin-left: .5em; vertical-align: middle" class="image-text">$
								{{ data.amount }}</span>
						</template>
					</Column>

					<Column field="status" header="Status" :style="{ width: '200px' }">
						<template #body="{ data }">
							<span style="margin-left: .5em; vertical-align: middle" class="image-text"
								v-if="data.status === 1">Pending</span>
							<span style="margin-left: .5em; vertical-align: middle" class="image-text"
								v-else-if="data.status === 2">Paid</span>
							<span style="margin-left: .5em; vertical-align: middle" class="image-text"
								v-else-if="data.status === 3">Declined</span>
							<span style="margin-left: .5em; vertical-align: middle" class="image-text" v-else>-</span>

						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import apiStore from '../store/index';
export default {
	data() {
		return {
			customCharges: []
		}
	},
	interval: null,
	async mounted() {
		this.resetInputs();
		await this.loadCustomCharges()
	},
	computed: {
		'selectedStore': function() {
			return apiStore.getters["storeinternal/getSelectedStore"]
		}
	},
	watch: {
		'selectedStore': function(newVal, oldValue){
			this.loadCustomCharges()
		}
	},
	methods: {
		inputHandler() {
			if (this.amount > 3000) {
				this.amount = 3000;
			}
		},
		resetInputs() {
			this.amount = 0
			this.description = 'One-time charge for customization work'
		},
		async loadCustomCharges() {
			var installationId = this.selectedStore.id
			var response = await apiStore.dispatch('billing/getCustomCharges', { id: installationId })
			if(response !== undefined)
				this.customCharges = response
		},
		async addCustomCharge() {
			var payload = {
						'installationId': this.selectedStore.id,
						'description': this.description,
						'amount': parseFloat(this.amount).toFixed(2)
					};

			try{
				var response = await apiStore.dispatch('billing/addCustomCharge', payload)
				if(response !== undefined) {
					await this.loadCustomCharges()
					this.resetInputs()
					this.$wkToast('Charge Added Successfully');
				}
			}
			catch(exception){
				console.log('exception', exception.message)
				this.$wkToast(exception.message,{ className: ['wk-alert'] });
			}
		},
		startProgress() {
			this.interval = setInterval(() => {
				let newValue = this.value + Math.floor(Math.random() * 10) + 1;
				if (newValue >= 100) {
					newValue = 100;
				}
				this.value = newValue;
			}, 2000);
		},
		endProgress() {
			clearInterval(this.interval);
			this.interval = null;
		}
	}
}
</script>